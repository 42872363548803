<script>
/* eslint-disable */
import LeilaoMixin from "components/leiloes/components/LeilaoMixin"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import FilaVendasLeiloes from "components/leilao-venda-fila-espera/components/List.vue"

export default {
  name: 'LeilaoFormalizacao',
  inject: ['page'],
  mixins: [LeilaoMixin],
  components: {FilaVendasLeiloes, ECol, ERow},
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Formalização de Vendas do Leilão',
    name: 'LeilaoFormalizacao'
  }
}
</script>

<template>
  <div class="">
    <div class="content">
      <header>
        <e-row mr>
          <e-col><h2 class="component-title">Fila de Formalização de Vendas do Leilão</h2></e-col>
          <e-col class="text-right">
          </e-col>
        </e-row>
      </header>

      <div class="body">
        <fila-vendas-leiloes :leilao="leilao" />
      </div>
    </div>
  </div>
</template>
